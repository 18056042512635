<template>
    <div class="container">
        <div class="edit">
            <div class="header">
                <div class="left">
                    <span>{{editForm.id ? '编辑' : '新增'}}月评模版</span>
                </div>
                <div class="right" >
                    <el-button round @click="handleCancel">取消</el-button>
                    <el-button type="primary" round @click="handleSave" :disabled="savedBtnDisadled">保存</el-button>
                </div>
            </div>
            <div class="edit-container">
                <div class="edit-header">
                    <div class="floor">月评模板</div>
                </div>
                <div class="edit-content">
                    <resources-item title="单元主题">
                        <div class="edit-form">
                            <el-col class="edit-form-item" :lg="11">
                                <div class="left">主题名称</div>
                                <div class="right">
                                    <el-input class="form-item"
                                        v-model="editForm.name" placeholder="请输入内容">
                                    </el-input>
                                </div>
                            </el-col>
                            <el-col class="edit-form-item" :lg="13">
                                <div class="left">主题时间</div>
                                <div class="right">
                                    <div class="date-picker-group form-item">
                                        <el-date-picker class="date-picker" v-model="editForm.beginTime" type="date"
                                            value-format="timestamp" @blur="checkTime" placeholder="选择开始日期">
                                        </el-date-picker>
                                        <div class="split-line">-</div>
                                        <el-date-picker class="date-picker" v-model="editForm.endTime" type="date"
                                            value-format="timestamp" @blur="checkTime" placeholder="选择结束日期">
                                        </el-date-picker>
                                    </div>
                                </div>
                            </el-col>
                            <el-col class="edit-form-item target" :span="24">
                                <div class="left">主题目标</div>
                                <div class="right">
                                    <el-input class="circular-bead-input form-item" type="textarea" style=""
                                        v-model="editForm.target" placeholder="请输入内容"
                                        :autosize="{ minRows: 4}">
                                    </el-input>
                                </div>
                            </el-col>
                        </div>
                    </resources-item>
                    <resources-item title="发展目标">
                        <div class="edit-faq" 
                            v-for="(configItem,configIndex) in editForm.config" 
                            :key="configIndex" v-show="configItem.type === 'ul-faq'">
                            <div v-for="(faqItem, faqIndex) of configItem.content"
                                :key="faqIndex" class="edit-faq-item">
                                <div class="edit-faq-item-title">
                                    <el-col class="left" :lg="15" :sm='11'>
                                        <div class="index"><span>{{'0'+(faqIndex+1)}}</span></div>
                                        <div class="name">
                                            <el-input type="text" v-model="faqItem.title" placeholder="请输入目标标题"></el-input>
                                        </div>
                                    </el-col>
                                    <el-col class="right" :lg="9" :sm='13'>
                                        <el-button icon="el-icon-delete" round 
                                            @click="handleDelete(faqIndex)">删除</el-button>
                                        <el-button icon="el-icon-upload2" round
                                            @click="handleMove(faqIndex,-1)">上移</el-button>
                                        <el-button icon="el-icon-download" round
                                            @click="handleMove(faqIndex,1)">下移</el-button>
                                    </el-col>
                                </div>
                                <el-collapse class="edit-faq-item-list">
                                    <el-collapse-item  class="list-item"
                                        v-for="(listItem, listIndex) in faqItem.list" :key="listIndex">
                                        <template slot="title"> 
                                            <div class="list-item-title">
                                                <el-col class="left" :lg="22" :sm='23'>
                                                    <div class="index"><span>{{listIndex+1}}</span></div>
                                                    <div class="name">
                                                        <el-input @click.native.stop
                                                        placeholder="请输入内容" v-model="listItem.name" ></el-input>
                                                    </div>
                                                </el-col>
                                                <el-col class="right" :lg="2" :sm='1'>
                                                    <i class="el-icon-remove-outline" title="移除本栏"
                                                        @click.stop="handleDeleteList(faqIndex,listIndex)"></i>
                                                </el-col>
                                            </div>
                                        </template>
                                        <div class="edit-faq-item-icon" 
                                            v-for="(optionsItem, optionsIndex) in listItem.options" :key="optionsIndex">
                                            <el-col class="left" :span="4">
                                                <icon-select v-model="optionsItem.icon" placeholder="icon匹配" popper-class='icon-select-dropdown'>
                                                    <el-option v-for="(item,index) in iconList" :key="index"
                                                        :label="item.src" :value="item.index">
                                                        <img :src="item.src" alt="" :title='item.desc' />
                                                    </el-option>
                                                    <template slot="prefix"></template>
                                                </icon-select>
                                            </el-col>
                                            <el-col class="right" :span="20">
                                                <el-input placeholder="请输入内容" v-model="optionsItem.value"></el-input>
                                                <i class="el-icon-remove-outline" title="移除此项"
                                                    @click="handleDeleteOptions(faqIndex,listIndex,optionsIndex)"></i>
                                            </el-col>
                                        </div>
                                        <div class="edit-faq-item-icon">
                                            <div class="add-options" @click="handleAddOptions(faqIndex,listIndex)">
                                                <span><i class="el-icon-circle-plus-outline"></i>添加选项</span>
                                            </div>
                                        </div>
                                    </el-collapse-item>
                                    <el-row class="list-item">
                                        <div class="list-item-title">
                                            <el-col class="left" :lg="22" :sm='23'>
                                                <div class="index add-list" title="新增一栏"
                                                    @click="handleAddList(faqIndex)"><i class="el-icon-plus"></i></div>
                                                <div class="name">
                                                    <el-input placeholder="请输入内容" @keyup.enter.native="handleAddList(faqIndex)" v-model="addListName[faqIndex]"></el-input>
                                                </div>
                                            </el-col>
                                            <el-col class="right" :lg="2" :sm='1'>
                                            </el-col>
                                        </div>
                                    </el-row>
                                </el-collapse>
                            </div>
                        </div>
                        <div class="edit-faq" >  
                            <div class="edit-faq-add" @click="handleAdd">
                                <span><i class="el-icon-plus"></i>新增目标类型</span>
                            </div>
                        </div>
                    </resources-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Core from '@/core';
import _ from '@kaobeimao/core';
import ResourcesItem from '@/components/resources/ResourcesItem';

export default {
    components: {
        ResourcesItem,
        IconSelect: () => import('./components/IconSelect')
    },
    data() {
        return {
            loading: false,
            userType: Core.Data.getUserType(),
            iconList: Core.Const.EVALUATION_ICONLIST,
            addListName: [''],
            editForm: {
                id: '',
                plan_id: '',
                name: '',
                month: '',
                target: '',
                endTime: '',
                beginTime: '',
                config: Core.Utils.deepCopy(Core.Const.EVALUATION_CONFIG.DEFAULT),
            },
            isSaved: false,
            savedBtnDisadled: false
        };
    },
    created() {
        this.editForm.id = this.$route.query.id;
        this.editForm.plan_id = this.$route.query.plan_id;
        let now = new Date();
        this.editForm.beginTime = now.setHours(0,0,0,0);
        this.editForm.endTime = now.setMonth(now.getMonth()+1);
        if(this.editForm.id){
            this.getEvaluation()
        }
    },
    methods: {
        getEvaluation(){
            console.log("getEvaluation -> this.editForm.id", this.editForm.id);
            Core.Api.Evaluation.detail(
                this.editForm.id
            ).then(res => {
                console.log("getEvaluation -> res", res)
                let item = res.detail
                this.editForm.name = item.topic.name;
                this.editForm.month = item.month;
                this.editForm.target = item.topic.target;
                this.editForm.config = JSON.parse(item.config);
                this.editForm.beginTime = new Date(item.begin_time * 1000);
                this.editForm.endTime = new Date(item.end_time * 1000);
                console.log("getEvaluation -> this.editForm", this.editForm)
            })
        },
        test(){ // console.log用
            console.log("test -> this.editForm", this.editForm)
            this.clearEditForm()
        },
        clearEditForm(){ // editForm初始化
            /*this.editForm = {
                id: '',
                plan_id: '',
                name: '',
                month: '',
                target: '',
                endTime: '',
                beginTime: '',
                config: [{
                    title: '发展目标',
                    type: 'ul-faq',
                    content: [
                        { 
                            title: '', 
                            list: [
                                { 
                                    name: '', 
                                    options:[
                                        { icon: '', value: '' }
                                    ] 
                                }
                            ]
                        }
                    ]
                },],
                }
            */
            this.editForm = this.$options.data().editForm
            // console.log("clearEditForm -> this.editForm", this.editForm)
        },
        handleCancel(){ //点击取消
            // console.log('handleCancel');
            this.$confirm('确定要取消本次更改吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$router.go(-1);
                this.isSaved = true
                this.clearEditForm()
            })
        },
        handleSave(){ //点击保存
            console.log('handleSave -> this.editForm',this.editForm);
            this.savedBtnDisadled = true
            if(!this.editForm.name){
                return this.$message.warning('请填写主题名称');
            } else if(!this.editForm.target){
                return this.$message.warning('请填写主题目标');
            } else if(!this.editForm.beginTime){
                return this.$message.warning('起始时间不可为空');
            } else if(!this.editForm.endTime){
                return this.$message.warning('结束时间不可为空');
            } else if (this.editForm.endTime < this.editForm.beginTime) {
                return this.$message.warning('请选择合理的时间范围');
            }
            let month = new Date((this.editForm.beginTime/1 + this.editForm.endTime/1)/2).getMonth()+1; 
            
            Core.Api.Evaluation.save(
                this.editForm.id,
                this.editForm.plan_id,
                this.editForm.beginTime / 1000,
                this.editForm.endTime / 1000,
                this.editForm.name,
                this.editForm.target,
                month,
                JSON.stringify(this.editForm.config),
            ).then((res) => {
                console.log("handleSave -> res", res)
                this.$message.success('保存成功!');
                this.isSaved = true
                this.$router.go(-1);
                this.clearEditForm()
            }).finally(e => {
                this.savedBtnDisadled = false
            });
        },
        handleAdd(){ //新增整个表格
            // console.log('handleAdd');
            this.editForm.config[0].content.push({
                title: '',
                list:[]
            })
            this.addListName.push('')
            console.log("handleAdd -> this.editForm", this.editForm.config[0].content)

        },
        handleDelete(faqindex){ //删除整个表格
            console.log('handleDelete',faqindex);
            this.$confirm('确定要删除这项吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.editForm.config[0].content.splice(faqindex,1)
                    this.addListName.splice(faqindex,1)
                    this.$message.success('删除成功!');
                    console.log("editForm.config", this.editForm.config)
                }).catch(() => {
                    this.$message.info('取消删除');
            });
        },
        handleMove(faqindex,type){ //点击上移下移
            console.log('handleMove',faqindex,type);
            let len = this.editForm.config[0].content.length
            if((faqindex === 0 && type === -1) || (faqindex+1 === len && type === 1)){
                return
            }
            let item = this.editForm.config[0].content.splice(faqindex,1)[0]
            this.editForm.config[0].content.splice(faqindex+type,0,item)
            let newName = this.addListName.splice(faqindex,1)[0]
            this.addListName.splice(faqindex+type,0,newName)
        },
        handleAddList(faqindex){ //新增表格某行
            console.log('handleAddList',faqindex);
            this.editForm.config[0].content[faqindex].list.push({
                name: this.addListName[faqindex],
                options:[]
            })
            this.addListName[faqindex] = ''
            console.log("handleAddList -> nameList", this.editForm.config[0].content[faqindex].list)
        },
        handleDeleteList(faqindex,listindex){ //删除表格某行
            console.log('handleDeleteList',faqindex,listindex);
            this.$confirm('确定要删除这行吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.editForm.config[0].content[faqindex].list.splice(listindex,1)
                    this.$message.success('删除成功!');
                });
            console.log("handleDeleteList -> nameList", this.editForm.config[0].content[faqindex].list[faqindex].list)
        },
        handleAddOptions(faqIndex,listIndex){ //新增icon描述
            console.log('handleAddOptions',faqIndex,listIndex);
            this.editForm.config[0].content[faqIndex].list[listIndex].options.push({
                icon:'',
                value:''
            })
            console.log("handleAddOptions -> optionList", this.editForm.config[0].content[faqIndex].list[listIndex].options)
        },
        handleDeleteOptions(faqIndex,listIndex,optionsIndex){ //删除icon描述
            console.log('handleDeleteOptions',faqIndex,listIndex,optionsIndex);
            this.editForm.config[0].content[faqIndex].list[listIndex].options.splice(optionsIndex,1)
            console.log("handleAddOptions -> optionList", this.editForm.config[0].content[faqIndex].list[listIndex].options)
        },
        routerChange(key, q = '') { // 路由跳转
            switch (key) {
                case 1:
                    this.$confirm('确定要离开此页面吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$router.go(-1);
                        this.isSaved = true
                        this.clearEditForm()
                    })
                break;
            }
        },
        checkTime(type){  // 检查时间合理性
            if (this.editForm.endTime && this.editForm.beginTime) {
                if (this.editForm.endTime < this.editForm.beginTime) {
                    this.$message.warning('请选择合理的时间范围');
                }
            }
        },
    },
    beforeRouteLeave (to, from, next) {
        console.log("beforeRouteLeave -> to", to)
        console.log("beforeRouteLeave -> from", from)
        if(this.isSaved !== true){
            this.$confirm('确定要离开本页面吗吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                next()
            })
        } else{
            next()
        }
    },
};
</script>

<style lang="scss" scoped>
.container {
    .edit {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        .header {
            @include flex(row, space-between, center);
            margin-bottom: 14px;
            .left {
                font-size: 20px;
                font-weight: 500;
                color: rgba(0, 0, 0, 1);
                @include ftb;
                .el-icon-back {
                    font-size: 24px;
                    color: #1D517E;
                    font-weight: 800;
                    margin-right: 10px;
                    cursor: pointer;
                }
                span {
                    margin-right: 14px;
                }
            }
            
        }
        .edit-container {
            border-radius: 6px;
            margin-bottom: 30px;
            .edit-header {
                width: 100%;
                background: linear-gradient(
                    180deg,
                    rgba(251, 252, 253, 1) 0%,
                    rgba(246, 248, 250, 1) 100%
                );
                border-radius: 6px 6px 0 0;
                .floor {
                    width: 100%;
                    height: 60px;
                    box-sizing: border-box;
                    padding: 0 34px;
                    border-radius: 6px 6px 0px 0px;
                    @include flex(row, space-between, center);
                }
            }
            .edit-content {
                width: 100%;
                box-sizing: border-box;
                padding-bottom: 30px;
                background-color: #fff;
                .edit-form {
                    width: 100%;
                    @include flex(row, flex-start, center);
                    flex-wrap: wrap;
                    .edit-form-item {
                        // width: 100%;
                        margin-bottom: 20px;
                        @include ftb;
                        &.target{
                            align-items: flex-start;
                        }
                        .left {
                            width: 80px;
                            font-weight: 600;
                        }
                        .right {
                            width: calc(100% - 80px);
                            padding-right: 3vw;
                            .form-item{
                                width: 100%;
                                // max-width: 500px;
                                box-sizing: border-box;
                                input{
                                    width: 100%;
                                    min-width: 150px;
                                    font-size: 14px;
                                }
                                textarea{
                                    white-space: pre-wrap;
                                    min-width: 200px;
                                    font-size: 14px;
                                }
                                .date-picker{
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
                .edit-faq {
                    width: 100%;
                    .edit-faq-item {
                        width: 100%;
                        margin-bottom: 30px;
                        background:#f9fafc;
                        border-radius:4px;
                        border:1px solid #eaeef1;
                        
                        .index{
                            width: 44px;
                            height: 44px;
                            font-size:16px;
                            font-weight:600;
                            color:#1D517E;
                            line-height:44px;
                            text-align: center;
                            i{
                                font-weight:600;
                            }
                        }
                        .name{
                            width: calc(100% - 44px);
                            overflow: hidden;
                            .el-input,.el-textarea{
                                width: 90%;
                                input,textarea{
                                    color:rgba(50,53,56,1);
                                    font-size: 14px;
                                    background-color: transparent;
                                    border-color: transparent;
                                    padding-left: 0;
                                    transition: all .3s linear;
                                    &:focus{
                                        background-color: #fff;
                                        border-color: #1D517E;
                                        padding-left: 15px;
                                    }
                                }
                            }
                        }
                        &-title{
                            width: 100%;
                            height:44px;
                            @include flex(row, space-between, center);
                            .left{
                                @include flex(row, flex-start, center);
                                .name{
                                    .el-input{
                                        max-width: 400px;
                                        input{
                                            font-weight:600;
                                        }
                                    }
                                }
                            }
                            .right{
                                text-align: right;

                                .el-button:last-child{
                                    margin-right: 20px;
                                }
                            }
                        }
                        &-list{
                            .list-item{
                                .el-collapse-item__header{
                                    min-height: 44px;
                                    line-height: 44px;
                                    border-bottom: 1px solid #eaeef1;
                                    padding-right: 20px;
                                    &:hover{
                                        background-color:rgba(234, 238, 241, 1);
                                        .list-item-title{
                                            background-color:rgba(234, 238, 241, 1);
                                        }
                                    }
                                    i{
                                        font-size: 20px;
                                        color: rgba(24, 39, 64, 0.3);
                                    }
                                }
                                .el-collapse-item__content{
                                    background-color: #F9FBFC;
                                    box-sizing: border-box;
                                    padding: 10px 20px 44px 44px;
                                    overflow: hidden;
                                }
                                &-title{
                                    width: 100%;
                                    @include flex(row, space-between, center);
                                    background-color: #fff;
                                    .left{
                                        @include flex(row, space-between, center);
                                        .index{
                                            border-right: 1px solid rgba(234,238,241,1);
                                        }
                                        .name{
                                            padding-left: 20px;
                                            box-sizing: border-box;
                                            @include flex(row, flex-start, center);
                                        }
                                    }
                                    .right{
                                        @include flex(row, flex-end, center);
                                        padding-right: 10px;
                                        .el-icon-remove-outline {
                                            &:hover{
                                                color: #1D517E;
                                            }
                                        }
                                    }
                                    .add-list{
                                        &:hover{
                                            background-color:#fcf1f2;
                                        }
                                    }
                                }
                                .edit-faq-item-icon{
                                    margin-bottom: 4px;
                                    overflow: hidden;
                                    background-color: #fff;
                                    input{
                                        border-radius: 0;
                                    }
                                    .left{
                                        border: 1px solid #DCDFE6;
                                        .icon-select{
                                            width: 100%;
                                            box-sizing: border-box;
                                            input:read-only{
                                                visibility: hidden;
                                                width: 100%;
                                                height: 34px;
                                            }
                                            .el-input__prefix{
                                                width: calc(100% - 30px);
                                                left: 0;
                                                top: 0;
                                                text-align: center;
                                                @include flex(row, center, center);
                                            }
                                        }
                                    }
                                    .right{
                                        @include flex(row, space-between, center);
                                        border: 1px solid #DCDFE6;
                                        box-sizing: border-box;
                                        border-left-color: transparent;
                                        .el-input{
                                            input{
                                                border: 0;
                                                height: 34px;
                                            }
                                        }
                                        i{
                                            padding-right: 20px;
                                            font-size: 16px;
                                            color: #1D517E;
                                        }
                                        
                                    }
                                    .add-options{
                                        background-color: transparent;
                                        height: 36px;
                                        line-height: 36px;
                                        text-align: center;
                                        font-size: 14px;
                                        color: rgba(0, 0, 0, 0.65);
                                        font-weight:400;
                                        border-radius:4px;
                                        border:1px dashed rgba(217,217,217,1);
                                        cursor: pointer;
                                        i{
                                            padding-right: 10px;
                                        }
                                        &:hover{
                                            color: #1D517E;
                                            border-color: #f5d4d8;
                                        }
                                    }
                                }
                                .right i{
                                    cursor:pointer
                                }
                            }
                        }
                    }
                    &-add {
                        width: 100%;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.65);
                        font-weight:400;
                        border-radius:4px;
                        border:1px dashed rgba(217,217,217,1);
                        cursor: pointer;
                        i{
                            padding-right: 10px;
                        }
                        &:hover{
                            color: #1D517E;
                            border-color: #f5d4d8;
                        }
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.container .edit .edit-container .edit-content {
    .edit-form .edit-form-item {
        .right {
            .form-item{
                input{
                    width: 100%;
                    min-width: 150px;
                    font-size: 14px;
                }
                textarea{
                    white-space: pre-wrap;
                    min-width: 200px;
                    font-size: 14px;
                }
            }
        }
    }
    .edit-faq {
        width: 100%;
        .edit-faq-item {
            width: 100%;
            margin-bottom: 30px;
            background:#f9fafc;
            border-radius:4px;
            border:1px solid #eaeef1;
            .index{
                width: 44px;
                height: 44px;
                font-size:16px;
                font-weight:600;
                color:#1D517E;
                line-height:44px;
                text-align: center;
                i{
                    font-weight:600;
                }
            }
            .name{
                width: calc(100% - 44px);
                overflow: hidden;
                .el-input,.el-textarea{
                    width: 90%;
                    input,textarea{
                        color:rgba(50,53,56,1);
                        font-size: 14px;
                        background-color: transparent;
                        border-color: transparent;
                        padding-left: 0;
                        transition: all .3s linear;
                        &:focus{
                            background-color: #fff;
                            border-color: #1D517E;
                            padding-left: 15px;
                        }
                    }
                }
            }
            &-title{
                width: 100%;
                height:44px;
                @include flex(row, space-between, center);
                .left{
                    @include flex(row, flex-start, center);
                    .name{
                        .el-input{
                            max-width: 400px;
                            input{
                                font-weight:600;
                            }
                        }
                    }
                }
                .right{
                    text-align: right;

                    .el-button:last-child{
                        margin-right: 20px;
                    }
                }
            }
            &-list{
                .list-item{
                    .el-collapse-item__header{
                        min-height: 44px;
                        line-height: 44px;
                        border-bottom: 1px solid #eaeef1;
                        padding-right: 20px;
                        &:hover{
                            background-color:rgba(234, 238, 241, 1);
                            .list-item-title{
                                background-color:rgba(234, 238, 241, 1);
                            }
                        }
                        i{
                            font-size: 20px;
                            color: rgba(24, 39, 64, 0.3);
                        }
                    }
                    .el-collapse-item__content{
                        background-color: #F9FBFC;
                        box-sizing: border-box;
                        padding: 10px 20px 44px 44px;
                        overflow: hidden;
                    }
                    &-title{
                        width: 100%;
                        @include flex(row, space-between, center);
                        background-color: #fff;
                        .left{
                            @include flex(row, space-between, center);
                            .index{
                                border-right: 1px solid rgba(234,238,241,1);
                            }
                            .name{
                                padding-left: 20px;
                                box-sizing: border-box;
                                @include flex(row, flex-start, center);
                            }
                        }
                        .right{
                            @include flex(row, flex-end, center);
                            padding-right: 10px;
                        }
                        .add-list{
                            &:hover{
                                background-color:#fcf1f2;
                            }
                        }
                    }
                    .edit-faq-item-icon{
                        margin-bottom: 4px;
                        overflow: hidden;
                        background-color: #fff;
                        input{
                            border-radius: 0;
                        }
                        .left{
                            border: 1px solid #DCDFE6;
                            .icon-select{
                                width: 100%;
                                box-sizing: border-box;
                                input:read-only{
                                    visibility: hidden;
                                    width: 100%;
                                    height: 34px;
                                }
                                .el-input__prefix{
                                    width: calc(100% - 30px);
                                    left: 0;
                                    top: 0;
                                    text-align: center;
                                    @include flex(row, center, center);
                                }
                            }
                        }
                        .right{
                            @include flex(row, space-between, center);
                            border: 1px solid #DCDFE6;
                            box-sizing: border-box;
                            border-left-color: transparent;
                            .el-input{
                                input{
                                    border: 0;
                                    height: 34px;
                                }
                            }
                            i{
                                padding-right: 20px;
                                font-size: 16px;
                                color: #1D517E;
                            }
                            
                        }
                        .add-options{
                            background-color: transparent;
                            height: 36px;
                            line-height: 36px;
                            text-align: center;
                            font-size: 14px;
                            color: rgba(0, 0, 0, 0.65);
                            font-weight:400;
                            border-radius:4px;
                            border:1px dashed rgba(217,217,217,1);
                            cursor: pointer;
                            i{
                                padding-right: 10px;
                            }
                            &:hover{
                                color: #1D517E;
                                border-color: #f5d4d8;
                            }
                        }
                    }
                    .right i{
                        cursor:pointer
                    }
                }
            }
        }
        &-add {
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            font-weight:400;
            border-radius:4px;
            border:1px dashed rgba(217,217,217,1);
            cursor: pointer;
            i{
                padding-right: 10px;
            }
            &:hover{
                color: #1D517E;
                border-color: #f5d4d8;
            }
        }
    }
}
</style>

<style lang="scss">  // 图标选择框的下拉
.icon-select-dropdown{
    ul.el-select-dropdown__list{
        @include flex(row, space-between, flex-start);
        flex-wrap: wrap;
        box-sizing: border-box;
        width: 220px;
        padding-left: 8px;
        padding-right: 8px;
        box-shadow:0px 2px 20px 0px rgba(70,79,85,0.2);
        li{
            width: 34px;
            height: 34px;
            line-height: 34px;
            padding: 7px;
            @include flex(row, center, center);
        }
    }
}
</style>